.getStartedOuterLayout {
  display: flex;
  max-width: 70%;
  min-width: 70%;
  margin: 2.5rem 15%;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    margin: 1.5rem 0%;
    flex-direction: column;
  }
}
.sparkLogo {
  margin-left: 4.8rem;
  margin-top: 1rem;
}
.goBackButton {
  margin-left: 4.375rem;
}
.sparkLogoLink {
  width: 60%;
}
.getStartedOuterLayout.extraOuterLayout {
  display: flex;
  max-width: 70%;
  min-width: 70%;
  margin: 2.5rem 15%;
}
.newGetStartedInnerComponent {
  max-width: 60%;
  min-width: 60%;
  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.InnerComponentOfferLetter {
  max-width: 100%;
  min-width: 100%;
  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.newGetStartedInnerBusinessComponent {
  max-width: 75%;
  min-width: 75%;

  @media (max-width: 992px) {
    max-width: 90%;
    min-width: 90%;
  }
}
.getStartedInnerLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  padding-left: 3.5rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
    padding-left: 0;
  }
  h3 {
    color: var(--colorBackgroundPurple);
  }
  p {
    color: var(--colorDarkPurple);
  }
  &.tiny {
    max-width: 90%;
    min-width: 90%;
    margin: 0 5%;
  }

  .step {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #677684;
  }

  .getStartedCardsLayout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .viewText {
    color: #170d33;

    font-size: 14px;
    font-weight: 400;
  }
  .viewText:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .dateText {
    color: #81909d;
    font-size: 12px;
    font-weight: 400;
  }
}
.requestOnboarding {
  display: flex;
  justify-content: center;
}

.finalPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  max-width: 40%;
  min-width: 40%;
  margin: 0 30%;

  @media (max-width: 768px) {
    max-width: 60%;
    min-width: 60%;
    margin: 0 20%;
  }

  .thumb {
    background-color: var(--colorWhite);
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    color: #677684;
    text-align: center;
    margin: 1rem 0;
  }
}
.titleForUpload {
  color: #170d33;
  // font-size: 0.875rem;
  font-size: 1.3rem;
  // font-weight: 900;
  font-weight: 900;

  .smallSpanTitleRequired {
    color: red;
    font-size: 0.8rem;
    font-weight: 900;
  }
  .smallSpanTitleOptional {
    color: var(--colorMidPurple);
    font-size: 0.8rem;
    font-weight: 900;
  }
}

.inputFileBorder {
  border: 1px solid #d1cfd6 !important;
  border-radius: 10px;
}
.getStartedOnboardBusiness {
  grid-template-columns: repeat(1, 1fr) !important;
}
.offerLetterCards {
  max-width: 90%;
  min-width: 90%;
}

.questionCheckbox input[type="checkbox"]:checked {
  color: "#25AD25";
}

//GetStartedUploadDocuments border line
.hrLine {
  display: block;
  border: 1px solid #d1cfd6;
  width: 100%;
  margin: 30px 0;
}

.cancelboarding {
  border-radius: 10px;
}
.upload_hint_alert {
  margin-left: 12rem;
  @media (max-width: 750px) {
    margin-left: 0.2rem;
  }
}
.upload_loader {
  margin-left: 8rem;
}
//general business Information form centralization
.center_Container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4.37rem;
  margin-right: 4.37rem;
  margin-top: 1.5rem;
}
.center_form {
  width: 100%;
  max-width: 478px;
  margin: auto;
}
.center_form_shareholder {
  width: 100%;
  max-width: 850px;
  margin: auto;
}

.logo_progressbar_container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4.37rem;
  margin-right: 4.37rem;
  margin-top: 3.75rem;
}

// progress bar

.progressContainer {
  width: 100%;
  max-width: 478px;
  position: relative;
  margin: auto;
  // padding-bottom: 3.75rem;
}

.barmarkerContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.barmarker {
  width: 100%;
  display: grid;
  place-content: center;
  color: #170d33;
  font-size: 0.75rem;
  &:hover {
    color: var(--colorMidPurple);
  }
  @media (max-width: 510px) {
    font-size: 0.375rem;
  }
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  // margin-top: 5px;
  position: relative;
}

.progress::-webkit-progress-bar {
  background-color: #fcdac8;
  border-radius: 4px;
}

.progress::-webkit-progress-value {
  background-image: linear-gradient(90.66deg, #ff5e2e 1.4%, #5c34ce 97.43%);
  border-radius: 4px;
}

.previewType {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #8b8699;
}
.previewText {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 18px;
  color: #170d33;
}

// File Input styling

.file_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  width: 100%;
  position: relative;
  border-radius: 10px;
}

.file_input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file_input > .file_button {
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  color: var(--colorMidGrayish);
}

.file_input:hover > .file_button {
  color: var(--colorLightGrey);
}

.file_input > .label_file {
  color: #333;
  white-space: nowrap;

  // opacity: 0.3;
  margin: 0;
  @media (max-width: 550px) {
    font-size: 0.7rem;
  }
}

.file_input._chosen > .label_file {
  opacity: 1;
}

// Quick info card
.onboarding_info_card {
  color: #f5a623;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid #f5a623;
  background-color: #fef4e5;
  margin: 2.5rem 0;
}
