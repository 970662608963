.center_container_feedbackLoan {
  width: 100%;
  max-width: 700px;
  margin: auto;
}
.feedbackCommentData {
  padding: 0.5rem;
  background-color: var(--colorSidebarBackground);
  margin-top: 1rem;
  border-radius: 10px;
}

.leftCommentsAdmin {
  text-align: left;
  margin-bottom: 0.5rem;
}
.rightCommentsUser {
  text-align: right;
  margin-top: 1rem;
  p {
    padding: 0;
  }
}

.commentdetails {
  font-size: 0.75rem;
  font-weight: 400;
}
.nameOfUserComments {
  font-size: 0.7rem;
}
.nameOfUserType {
  font-size: 0.71rem;
  font-weight: 500;
}
.time {
  font-size: 0.65rem;
  font-weight: 300;
}
