// NEW FONTS
@font-face {
  font-family: font-regular;
  src: url("../assets/fonts/DM_Sans/DMSans-Regular.ttf");
}
@font-face {
  font-family: font-medium;
  src: url("../assets/fonts/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
  font-family: font-semi-bold;
  src: url("../assets/fonts/DM_Sans/DMSans-Bold.ttf");
}
// Old colors and fonts
// :root {
//   --colorBlue: #0064b8;
//   --colorLightBlue: #93a5ff;
//   --lightBlue: #e3f2fc;
//   --colorMidBlue: #2c6eec;
//   --colorDarkBlue: #041644;
//   --colorBackgroundBlue: #0e347e;
//   --colorBackgroundHeaderBlue: #0f42a4;
//   --colorDimGrey: #707070;
//   --colorMidGrey: #d7dce0;
//   --colorMidGrayish: #505d67;
//   --colorLightBlack: #272525;
//   --colorLighterGrey: #f4f5f6;
//   --colorLightGreen: #00a006;
//   --colorLightGrey: #8d8585;
//   --colorDimGreen: #27be63;
//   --colorYellow: #ec9523;
//   --colorOrange: #f08829;
//   --colorRed: #ce0000;
//   --colorDimRed: #ca1b1b;
//   --cardBorderColor: #3a434b;
//   --colorWhite: #ffffff;
//   --white: #ffffff;
//   --colorBlack: #000000;
//   --borderRadius: 12px;
//   --borderRadiusSm: 6px;
//   --borderRadiusMd: 9px;
//   --borderRadiusLg: 15px;
//   --fontRegular: font-regular;
//   --fontSemiBold: font-semi-bold;
//   --fontBold: font-bold;
//   --fontMedium: font-medium;
//   --headerHeight: 65px;
//   --breakpoint-xs: 0;
//   --breakpoint-sm: 576px;
//   --breakpoint-md: 768px;
//   --breakpoint-lg: 992px;
//   --breakpoint-xl: 1200px;
// }

//Product Rebrand
:root {
  --colorBlue: #261555;
  --colorLightBlue: #93a5ff;
  --lightBlue: #e3f2fc;
  --colorMidBlue: #713fff;
  --colorDarkBlue: #041644;
  --colorBackgroundBlue: #200865;
  --colorBackgroundHeaderBlue: #200865;
  --colorDimGrey: #707070;
  --colorMidGrey: #d7dce0;
  --colorMidGrayish: #505d67;
  --colorLightBlack: #272525;
  --colorLighterGrey: #f4f5f6;
  --colorLightGreen: #00a006;
  --colorLightGrey: #8d8585;
  --colorDimGreen: #27be63;
  --colorYellow: #ec9523;
  --colorOrange: #f08829;
  --colorRed: #ce0000;
  --colorDimRed: #ca1b1b;
  --cardBorderColor: #3a434b;
  --colorWhite: #ffffff;
  --white: #ffffff;
  --colorBlack: #000000;
  --borderRadius: 12px;
  --borderRadiusSm: 6px;
  --borderRadiusMd: 9px;
  --borderRadiusLg: 15px;
  --fontRegular: font-regular;
  --fontSemiBold: font-semi-bold;
  --fontBold: font-semi-bold;
  --fontMedium: font-medium;
  --headerHeight: 65px;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  // New UI REVAMP
  --colorPurple: #261555;
  --colorBackgroundPurple: #200865;
  --colorMidPurple: #713fff;
  --colorDarkPurple: #16054e;
  --colorMidShadePurple: #a07fff;
  --colorHoverState: #e3d9ff;
  --colorSidebarBackground: #f2f5f9;
  --colorGreyPurple: #645e77;
  --colorDarkPurple: #170d33;
  --colorLightPurple: #f8f5ff;
  --colorLightGrey: #8b8699;
  --colorLighterGrey: #f0f0f0;
  --colorGrayCard: #f8f8f8;
  --colorDarkerGrayShade: #333333;
  --AdvanclyDarkPersianIndigo: #150849;
  --AdvanclyColorGrey: #d1cfd6;
  --AdvanclyBodyText: #001540;
}
body {
  line-height: 1.5;
  background-color: var(--colorWhite);
  color: var(--colorBlack);
  font-family: var(--fontRegular) !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: var(--colorMidGrey);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--colorMidGrey);
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--colorMidBlue);
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.text-underline {
  text-decoration: underline;
}
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

@mixin advancly-btn {
  border-radius: 10px;
  padding: 12px 32px;
  color: var(--colorWhite);
  font-weight: 600;

  &:hover {
    color: var(--colorWhite);
    font-weight: bold;
  }

  &:focus {
    box-shadow: unset;
    outline: 0;
  }
}

.advancly-btn {
  @include advancly-btn();
  border: none;
  background-color: var(--colorMidBlue);
  outline: 0;
}

.btn-sm {
  padding: 8px 16px !important;
}

.advancly-white-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorMidBlue);
  color: var(--colorMidBlue);

  &:hover {
    border-color: var(--colorMidBlue);
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-nobg-btn {
  @include advancly-btn();
  background-color: var(--colorWhite);
  border-color: var(--colorWhite);
  color: var(--cardBorderColor);

  &:hover {
    border-color: var(--colorWhite);
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}

.advancly-off-btn {
  @include advancly-btn();
  background-color: #e8effd;
  border-color: #e8effd;
  color: var(--colorMidBlue);

  &:hover {
    border-color: #e8effd;
    color: var(--colorMidBlue);
    font-weight: bold;
  }
}
.advancly-text-btn {
  border: none;
  color: var(--colorMidBlue);
  background-color: white;
  &:hover {
    color: var(--colorMidShadePurple);
  }
}

.advancly-grey-btn {
  @include advancly-btn();
  background-color: #81909d;
  border-color: #81909d;
  color: var(--colorMidBlue) !important;

  &:hover {
    border-color: #81909d;
    color: var(--colorMidBlue);

    font-weight: bold;
  }

  &.disabled {
    cursor: default;
    background-color: #fff;
    border-color: #81909d;
    color: #81909d;

    &:hover {
      border-color: #81909d;
      background-color: #fff;
    }
  }
}

.closeIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal_header {
  text-align: center;
}

.modal_title {
  color: #150849;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  font-family: "DM Sans";
  margin-top: 30px;
}

.action-content {
  color: #150849;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  font-family: "DM Sans";

  @media (max-width: 380px) {
    font-size: 14px;
  }

  @media (max-width: 280px) and (max-height: 653px) {
    font-size: 14px;
  }
}

.view-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 320px) {
    margin-top: 10px;
  }

  @media (max-width: 280px) and (max-height: 654px) {
    margin-top: 20px;
  }
}

.view-btn {
  place-self: center;
  border: none;
  background-color: #713fff;
  width: 177px;
  height: 50px;
  padding: 12px 16px;
  border-radius: 10px;
}

.center {
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.center:hover {
  color: #fff;
}

.advancly-red-btn {
  @include advancly-btn();
  background-color: #fce9e9;
  border-color: #fce9e9;
  color: var(--colorDimRed);

  &:hover {
    border-color: #fce9e9;
    color: var(--colorDimRed);
    font-weight: bold;
  }
}
.advancly-red-btn2 {
  @include advancly-btn();
  background-color: #ca1b1b;
  border-color: #ca1b1b;
  color: #fff;

  &:hover {
    background-color: #fce9e9;
    border-color: #fce9e9;
    color: var(--colorDimRed);
    font-weight: bold;
  }
}
.advancly-purple-btn {
  @include advancly-btn();
  background-color: #f8f5ff;
  color: #713fff;
  border: 1px solid #713fff;
  &:hover {
    background-color: #f8f5ff;
    color: #713fff;
  }
}

.input-loading {
  background-color: #ffffff;
  background-image: url("http://loadinggif.com/images/image-selection/3.gif");
  background-size: 25px 25px;
  background-position: center center;
  background-repeat: no-repeat;
}

.extra-info {
  border-color: #c4c4c4;
  color: #c4c4c4;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c4c4c4;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 8px;
}

.disabled-link {
  pointer-events: none;
}

.bg-white {
  background-color: var(--white);
}

.advancly-indigo-blue-text {
  color: #150849;
}

.bg-lightblue {
  background-color: var(--lightBlue);
}
.color-card-border-color {
  color: var(--cardBorderColor);
}
.card-border-wrapper {
  border: 0.5px solid #d1cfd6;
  border-radius: 20px;
  padding: 0.75rem 1.5rem;
}
.card-border-wrapper-medium {
  border: 0.5px solid #d1cfd6;
  border-radius: 20px;
  padding: 0.375rem 1.5rem;
}
.color-blue {
  color: var(--colorBackgroundBlue);
  &:hover {
    color: rgba(32, 8, 101, 0.6);
  }
}
.color-blue-hover-none {
  color: var(--colorBackgroundBlue);
}
.color-mid-blue {
  color: var(--colorMidBlue);
  &:hover {
    color: #b69cff;
  }
}
.color-mid-blue-hover-none {
  color: var(--colorMidBlue);
}
.color-advancly-dark-persian-indigo {
  color: var(--AdvanclyDarkPersianIndigo);
}
.color-light-blue {
  color: var(--colorBlue);
}
.color-grey-purple {
  color: var(--colorGreyPurple);
}
.bg-lighter-grey {
  background-color: var(--colorLighterGrey);
}
.bg-light-grey {
  color: var(--colorLightGrey);
}
.pending-loan-btn {
  display: flex;
}

.color-mid-grayish {
  color: var(--colorMidGrayish);
}
.color-dark-purple {
  color: var(--colorDarkPurple);
}

.horizontal-line {
  height: 2px;
  position: relative;
  background-color: var(--colorMidGrey);
  margin: 10px 0px;
}
.horizontal-line-thin {
  height: 1px;
  position: relative;
  background-color: var(--colorLighterGrey);
  margin: 10px 0px;
}
.horizontal-dash-line {
  border-bottom: 1px dashed #d1cfd6;
  width: 90%;
  margin: auto;
}

.page-title {
  font-weight: 600;
  font-size: clamp(24px, 2.5vw, 28px);
  line-height: 32px;
  letter-spacing: 0.25px;
  color: var(--colorBackgroundBlue);
  font-family: var(--fontSemiBold);
}
.page-subtitle {
  color: var(--cardBorderColor);
  font-family: var(--fontSemiBold);
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #0d0f11;
}
//input field
.text_label {
  color: var(--colorLightGrey);
}

// Advancly card wrapper
.advancly-wrapper-container {
  padding: 1.5rem;
  border: 1px solid var(--colorGrayCard);
  border-radius: 1.25rem;
  background-color: var(--colorGrayCard);
}
.advancly-sub-wrapper {
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.625rem;
}
.advancly-sub-wrapper-no-padding {
  padding: 0rem;
  background-color: #fff;
}
// default table styles
.table-style {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid var(--AdvanclyColorGrey);
  background-color: #fff;
  text-align: "center";

  .th-td-style {
    border: 0.5px solid var(--AdvanclyColorGrey);
    padding: 0.91rem 0.62rem;
    font-weight: normal;
    font-size: 0.875rem;
    color: var(--colorGreyPurple);
  }
  .th-style {
    color: var(--colorDarkPurple);
    font-weight: bold;
  }
}
// Make modal full height

.modal-dialog {
  position: fixed;
  margin: auto;
  // width: 65.5rem !important;
  width: 90%;
  height: 100%;

  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal-content {
  height: 100%;

  overflow-y: auto;
  border: 1px solid #fff;
  outline: none;
  border-radius: 0.625rem;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.remove-overflow {
  overflow-y: hidden;

  @media (max-width: 280px) and (max-height: 654px) {
    overflow-y: scroll;
  }
  @media (max-width: 1024px) and (max-height: 600px) {
    overflow-y: scroll;
  }
}
.modal-dialog {
  right: -320px;
  -webkit-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  -moz-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  -o-transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
  transition:
    opacity 0.3s linear,
    right 0.3s ease-out;
}

.modal-dialog {
  right: 0;
}
.modal-header {
  border: none;
}
.modal-title {
  padding: 0;
  color: var(--AdvanclyDarkPersianIndigo);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
}
.modal-footer {
  border: none;
  padding-bottom: 1rem;
}
.popup-box .modal-dialog {
  right: 32.25%;

  @media only screen and (max-width: 992px) {
    // right: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.popup-box .modal-dialog .modal-content {
  margin-top: 50%;
  height: 35%;

  @media only screen and (max-width: 992px) {
    height: 40%;
  }
}
.modal-body {
  padding: 15px 15px 80px;
}
//status colors
.approved_status {
  color: var(--colorDimGreen);
  background-color: #eafbf1;
  border-radius: 40px;
  padding: 4px 8px;
}
.pending_status {
  color: #f5a623;
  background-color: #fef4e5;
  border-radius: 40px;
  padding: 4px 8px;
}
.declined_status {
  color: #e43535;
  background-color: #fce9e9;
  border-radius: 40px;
  padding: 4px 8px;
}
@mixin tags {
  padding: 4px 8px;
  border-radius: 0.25rem;
  text-transform: capitalize;
}

@mixin activeTag {
  @include tags();
  background-color: #e9fbf1;
  color: #27be63;
}
@mixin inactiveTag {
  @include tags();
  color: #e6a900;
  background-color: #fff9e5;
}
@mixin successTag {
  @include tags();
  color: var(--colorDimGreen);
  background-color: #eafbf1;
}
@mixin pendingTag {
  @include tags();
  color: #5e4bce;
  background-color: #edebfa;
}
@mixin errorTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}
@mixin rejectedTag {
  @include tags();
  color: var(--colorDimRed);
  background-color: #fce9e9;
}

.dashboard-graph-list {
  padding: 0px;
  list-style: none;

  li {
    display: flex;
    padding: 8px 0px;
    margin-bottom: 4px;
    justify-content: space-between;
    flex-wrap: wrap;
    line-height: 16px;
    font-size: 14px;
    border-bottom: 1.5px dashed var(--colorMidGrey);

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        max-width: 65%;
      }
      &:nth-child(2) {
        max-width: 35%;
        &.active {
          @include activeTag();
        }
        &.inactive {
          @include inactiveTag();
        }
        &.success {
          @include successTag();
        }
        &.repaid {
          @include successTag();
        }
        &.pending {
          @include pendingTag();
        }
        &.error {
          @include errorTag();
        }
        &.rejected {
          @include rejectedTag();
        }
        &.failed {
          @include errorTag();
        }
        &.due {
          @include errorTag();
        }
        &.overdue {
          @include errorTag();
        }
      }
    }
    .dashbord-graph-list-name {
      color: var(--colorLightGrey);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1rem; /* 114.286% */
      letter-spacing: 0.025rem;
    }
    .dashboard-graph-list-amount {
      color: var(--colorDarkPurple);
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1rem; /* 114.286% */
      letter-spacing: 0.00175rem;
    }
  }
}
.dashboardComponent {
  display: flex;
  justify-content: space-between;
  padding-left: 2.06rem;
  padding-right: 6.25rem;

  @media only screen and (max-width: 992px) {
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
}
.dashboardComponent:not(:first-child) {
  border-left: 1px solid var(--AdvanclyColorGrey);
}

td {
  .active {
    @include activeTag();
  }
  .inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.overpaid {
    @include errorTag();
  }
}

span {
  &.active {
    @include activeTag();
  }
  &.inactive {
    @include inactiveTag();
  }
  &.success {
    @include successTag();
  }
  &.repaid {
    @include successTag();
  }
  &.pending {
    @include pendingTag();
  }
  &.error {
    @include errorTag();
  }
  &.rejected {
    @include rejectedTag();
  }
  &.failed {
    @include errorTag();
  }
  &.due {
    @include errorTag();
  }
  &.overdue {
    @include errorTag();
  }
  &.closed {
    @include inactiveTag();
  }
  &.approved {
    @include successTag();
  }
  &.writtenoff {
    @include errorTag();
  }
  &.rescheduled {
    @include pendingTag();
  }
  &.overpaid {
    @include errorTag();
  }
}

.crimson {
  color: crimson;
}

.form-label {
  font-size: 14px !important;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: capitalize !important;
}

.doc-page {
  padding: 12px;
  background: #f4f5f6;
  border-radius: 4px;
  max-height: 70vh;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #505d67;
  }
}

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/*------------------------------------
  Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: 500 !important;
  font-family: var(--fontBold);
}

.font-weight-semi-bold {
  font-weight: 600 !important;
  font-family: var(--fontBold);
}

.font-weight-bold {
  font-family: var(--fontBold);
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer-default {
  cursor: default !important;
}

.custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 16px;
  padding-left: 4px \9;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.custom-search-input span {
  border: 0;
  background: none;
  padding: 2px 16px 2px 0px;
  margin-top: 8px;
  position: relative;
  left: -28px;
  margin-bottom: 0;
  border: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: var(--cardBorderColor);
}
.search-query:focus + span {
  z-index: 3;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.alignToggleTabItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid var(--AdvanclyColorGrey);
  margin-bottom: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  &.centralize {
    justify-content: center;
  }

  .tabItem {
    margin: 0px 2.2rem 0px 0px;

    text-transform: capitalize;
    font-weight: 500;
    color: var(--colorLightGrey);
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: -0.005rem;
    padding-bottom: 0.75rem;
    white-space: nowrap;

    &.activeTab {
      font-weight: 500;
      color: var(--colorMidBlue);
      font-family: var(--fontBold);
      border-bottom: 2px solid var(--colorMidBlue);
      padding: 0 1.47rem 0 1.47rem;
    }
  }
}

.more-icon {
  cursor: pointer;
  &::before {
    content: url(../svg/icons/more-icon.svg);
  }
}
.more-icon-horizontal {
  cursor: pointer;
  &::before {
    content: url(../svg/icons/more-icon-horizontal.svg);
  }
}
.input-group {
  .form-control {
    height: 3.13rem !important;
    &:focus {
      box-shadow: 0px 7px 16px rgba(171, 180, 189, 0.23);
      border: 1px solid #ced4da;
      background-color: #fff;
    }
  }
  .input-group-append {
    .input-group-text {
      background-color: transparent;
      color: var(--colorMidBlue);
      font-size: 14px;
      line-height: 16px;

      &:hover {
        font-weight: bold;
        font-family: var(--fontBold);
      }
    }
  }
}

.disabled {
  pointer-events: none;
  cursor: default !important;
}

.custom-control-input {
  &:focus {
    ~ .custom-control-label::before {
      box-shadow: unset;
    }
  }
  &:checked ~ .custom-control-label {
    &::before {
      border-color: var(--colorDimGreen);
      background-color: var(--colorDimGreen);
    }
  }
}

.upload-box-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  .upload-box-icon-border {
    img {
      margin-bottom: 20px;
      height: 50px;
      width: auto;
    }

    background-color: var(--colorLighterGrey);
    padding: 80px;
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.input-group-prepend {
  .input-group-text {
    background-color: unset;
    border-right-width: 0;

    select {
      border: 0;

      &:focus-visible {
        outline: 0;
      }
    }
  }
}
.currency-badges {
  flex-direction: row;
  display: flex;
  > div {
    height: 32px;
    width: 32px;
    font-size: 1rem;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--colorWhite);
    background-color: var(--colorBlue);
    transform: translateX(-0.35rem);

    &:first-child {
      background-color: var(colorBlue);
      transform: translateX(0);
    }
  }
}
