.mainButtonOfferCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.uploadedDocCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--colorWhite);
  border: 1px solid var(--colorMidGrey);
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.13rem;
  padding-bottom: 1rem;
  margin: 1rem 0;
  width: 27rem;

  .left {
    // max-width: 80%;
    // min-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 100% */
    letter-spacing: 0.025rem;

    svg {
      margin-right: 1rem;
    }
  }

  .right {
    cursor: pointer;
    small {
      font-size: 0.75rem;
      color: #713fff;
    }
  }
}
