.loanAppTableHeading {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}
.center_container {
  width: 100%;
  max-width: 464px;
  // max-width: 698px;
  margin: auto;
}

.mainHeadingContainer {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  align-items: center;
}

.headingLoanApplication {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #200865;
  margin-left: 14.37rem;
}

// progress bar
.progressContainer {
  width: 100%;
  max-width: 698px;
  position: relative;
  margin: auto;
  // padding-bottom: 3.75rem;
}

.barmarkerContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  white-space: nowrap;
}

.barmarker {
  // width: 100%;
  display: grid;
  place-content: center;
  color: #170d33;
  font-size: 0.7rem;
  &:hover {
    color: var(--colorMidPurple);
  }
  @media (max-width: 510px) {
    font-size: 0.375rem;
  }
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  // margin-top: 5px;
  position: relative;
}

.progress::-webkit-progress-bar {
  background-color: #fcdac8;
  border-radius: 4px;
}

.progress::-webkit-progress-value {
  background-image: linear-gradient(90.66deg, #ff5e2e 1.4%, #5c34ce 97.43%);
  border-radius: 4px;
}

// loan Information
.InformationHeading {
  color: var(-- colorBackgroundPurple);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
}
.InfoParagraph {
  color: var(-- colorLightGrey);
  font-weight: 400;
  font-size: 1rem;
  font-weight: 400;
}
.addLoanInformation {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

// for dynamic questions
.questionCheckbox input[type="checkbox"]:checked {
  color: "#25AD25";
}

.financialDocumentUploadContainer {
  border: 1px dotted #d7dce0;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f5ff;
  padding: 3.5rem 6.5rem;

  .dropParagraph {
    color: #8b8699;
    font-size: 0.865rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.025rem;

    span {
      color: var(--colorMidBlue);
    }
  }
}

.dottedlines {
  border: 0.5px dotted #d1cfd6;
  margin: 2.5rem 0;
}

.financialDocumentListContainer {
  margin-top: 1rem 0;
  border-radius: 0.625rem;
  border: 0.5px dashed #d1cfd6;

  .financialListHeader {
    padding: 1rem;
    .financialListMainHeader {
      color: #8b8699;
      font-size: 0.875rem;
    }
  }

  .financialListHeaderMiniContainer {
    margin: 1rem;
    border-radius: 0.625rem;
    border: 0.5px solid #d1cfd6;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.56rem;
    padding-bottom: 0.56rem;
    .docdescriptionContainer {
      margin-left: 0.5rem;
      .documentName {
        color: #8b8699;
        font-size: 0.875rem;
      }
      .stat {
        max-width: 60%;
        display: block;
        font-size: 0.875rem;
      }
    }
  }
}

.listStyleType {
  list-style-type: none;
}

// financial instrument upload styling
.file_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  width: 100%;
  position: relative;
  border-radius: 10px;
}

.file_input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file_input > .file_button {
  display: inline-block;
  cursor: pointer;
  border-radius: 2px;
  color: var(--colorMidGrayish);
}

.file_input:hover > .file_button {
  color: var(--colorLightGrey);
}

.file_input > .label_file {
  color: #333;
  white-space: nowrap;

  // opacity: 0.3;
  margin: 0;
  @media (max-width: 550px) {
    font-size: 0.7rem;
  }
}

.file_input._chosen > .label_file {
  opacity: 1;
}

// Quick info card
.onboarding_info_card {
  color: #f5a623;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid #f5a623;
  background-color: #fef4e5;
  margin: 2.5rem 0;
}
// styling for folder
.uploadedDocCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--colorWhite);
  border: 1px solid var(--colorMidGrey);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 1rem 0;

  .left {
    max-width: 80%;
    min-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-right: 1rem;
    }
  }

  .right {
    cursor: pointer;
  }
}
