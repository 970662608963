.predisbursmentMainContainer {
  padding-top: 2.5rem;
}
.predisbursmentMainHeader {
  padding-bottom: 3rem;
  color: #8b8699;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  max-width: 698px;
  position: relative;
  margin: auto;
}

.predisbursementApprovedStatus {
  text-align: center;
  color: #25ad25;
  font-size: 0.75rem;
  border-radius: 2.5rem;
  background: #eaf9ea;
  padding: 0.25rem 0.5rem;
}
.predisbursementDeclinedStatus {
  text-align: center;

  font-size: 0.75rem;
  border-radius: 2.5rem;
  color: #ff193b;
  background: #fce9e9;
  padding: 0.25rem 0.5rem;
}
.predisbursementPendingStatus {
  text-align: center;
  font-size: 0.75rem;
  border-radius: 2.5rem;
  color: #f5a623;
  background-color: #fef4e5;
  padding: 0.25rem 0.5rem;
}
.predisbursementList {
  li {
    list-style: none;
    overflow-wrap: break-word;
    padding: 0.25rem;
  }
}
.prebursementFileUpload {
  small {
    color: #8b8699;
    font-size: 1rem;
    font-weight: 400;
    &:hover {
      color: #713fff;
    }
  }

  cursor: pointer;
}

// confimation upload modal
.UserManagementHeader {
  padding-bottom: 2.5rem;
}

.assignedRole {
  white-space: pre-line;
}

.updateUserConfirmationCancelButton,
.advanclyIconDialog {
  margin-bottom: 1.5rem;
}
.confirmationHeader {
  color: var(--AdvanclyDarkPersianIndigo);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.editRoleMain {
  padding-top: 0.82rem;
  padding-bottom: 1.18rem;
  &:hover {
    background-color: var(--colorSidebarBackground);
  }
}
.editRoleList {
  font-size: 0.875rem;
  color: var(--AdvanclyBodyText);
  font-weight: 400;
}
.buttonEditUserRole {
  padding-top: 3.75rem;
}
.predisbursementFileNameText {
  word-break: break-all;
}
