.loansOverviewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  cursor: pointer;
  scrollbar-width: thin; /* For Firefox and some other browsers */
  scrollbar-color: transparent transparent; /* For Firefox */
  cursor: pointer;
  /* For WebKit-based browsers (like Chrome and Safari) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar-track {
    background-color: transparent; /* or any color you prefer */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* or any color you prefer */
  }

  /* For Internet Explorer and Edge */
  -ms-overflow-style: none;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }
}

.loansOverViewSubContainer {
  display: flex;
  justify-content: center;

  .overviewTextContainer {
    padding-left: 0.5rem;

    .loanOverviewHeading {
      color: var(--colorLightGrey);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.5rem; /* 171.429% */
      letter-spacing: 0.025rem;
    }
    .loanOverviewParagraph {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.0125rem;
    }
  }
}
.loansOverViewSubContainer:not(:first-child) {
  border-left: 1px solid var(--AdvanclyColorGrey);
  padding-left: 2.06rem;

  @media only screen and (max-width: 1024px) {
    border-left: none;
    padding-left: 0;
    justify-self: center;
  }
}

// filter csv file
.dropDownfilterContainer {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid var(--AdvanclyColorGrey);
  cursor: pointer;

  .dropDownfilterSubContainer {
    padding: 0.75rem 0.9375rem;
    p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.dropdownFilterCardContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 400px;
  position: absolute;
  border: 0.5px solid #d1cfd6;
  border-radius: 0.625rem;
  padding: 1.25rem 1.5rem;
  z-index: 30000 t;
  background-color: #ffff;

  @media (max-width: 705px) {
    right: 5px;
  }
  @media (max-width: 406px) {
    left: 2px;
  }

  .downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 0.75rem;
    border: 1px solid#562eed;
    border-radius: 0.3125rem;
    color: #562eed;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
    letter-spacing: 0.025rem;
    span {
      margin-right: 0.62rem;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  .resetButton {
    display: flex;
    width: 8.875rem;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid #f6f5f7;
    background: #f6f5f7;
  }
  .applyButton {
    @extend .resetButton;
    background: #713fff;
    color: #fff;
  }
}
