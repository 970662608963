.loanOfferHeadingContainer {
  margin-left: 3.8rem;
  @media (max-width: 880px) {
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
  }
}

.offerLetterChoiceButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  button {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.0125rem;
    border-radius: 0.625rem;
    padding: 0.75rem 2rem;
    cursor: pointer;
  }
  button:first-child {
    color: #fff;
    background-color: #713fff;
    outline: none;
    border: 1px solid #713fff;
    &:hover {
      background-color: #fff;
      color: #200865;
    }
  }
  button {
    background-color: #fff;
    color: #713fff;
    border: 1px solid #713fff;

    &:hover {
      color: #fff;
      background-color: #200865;
    }
    &:disabled {
      border: 1px solid rgb(220, 220, 220);
      color: #fff;
      background-color: rgb(220, 220, 220);
      cursor: not-allowed;
    }
  }
}
.deleteContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.UploadButton {
  cursor: pointer;
  padding: 0.9375rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  border: 0.5px solid #713fff;
  background-color: #713fff;
  color: #fff;
}
.errorContainerMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 610px;
}

.viewOfferLetterButton {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #713fff;
  background-color: #fff;
}

// reupload modal
.UserManagementHeader {
  padding-bottom: 2.5rem;
}

.assignedRole {
  white-space: pre-line;
}

.updateUserConfirmationCancelButton,
.advanclyIconDialog {
  margin-bottom: 1.5rem;
}
.confirmationHeader {
  color: var(--AdvanclyDarkPersianIndigo);
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.paragraphConfirmationReupload {
  word-break: break-all;
}
.editRoleMain {
  padding-top: 0.82rem;
  padding-bottom: 1.18rem;
  &:hover {
    background-color: var(--colorSidebarBackground);
  }
}
.editRoleList {
  font-size: 0.875rem;
  color: var(--AdvanclyBodyText);
  font-weight: 400;
}
