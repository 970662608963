.networkStatusContainer {
  position: absolute;
  top: 60px;
  left: 40%;
  z-index: 5000;
  @media (max-width: 980px) {
    top: 80px;
    left: 0;
  }
}
